/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-10-05 23:44:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-10 10:29:02
 */
export default {
  /**
   * 日期格式化
   * @param {*} time
   * @returns
   */
  formatDate: (time) => {
    var date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return [year, month, day]
      .map((n) => {
        n = n.toString();
        return n[1] ? n : `0${n}`;
      })
      .join("-");
  },

  /**
   * 短日期格式 不含年份
   * @param {*} tiem
   */
  shortDate(time, separator) {
    var date = new Date(time);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return [month, day]
      .map((n) => {
        n = n.toString();
        return n[1] ? n : `0${n}`;
      })
      .join(separator ? separator : "-");
  },

  /**
   * 中国短日期格式 不含年份
   * @param {*} tiem
   */
  shortCNDate(time) {
    var date = new Date(time);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    var arr = [month, day]
      .map((n) => {
        n = n.toString();
        return n[1] ? n : `0${n}`;
      });
      return `${arr[0]}月${arr[1]}日`;
  },

};
